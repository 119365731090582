import { Box } from '@xstyled/styled-components';

export const FlexBox = ({ children, align, justify, dir, ...rest }) => {
  return (
    <Box
      display="flex"
      alignItems={align}
      justifyContent={justify}
      flexDirection={dir}
      {...rest}
    >
      {children}
    </Box>
  );
};

export const FlexColCenter = ({ children, ...rest }) => {
  return (
    <FlexBox dir="column" align="center" {...rest}>
      {children}
    </FlexBox>
  );
};
