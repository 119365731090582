import { Box } from '@xstyled/styled-components';

/**
 * Button
 *
 * Primitive button component allowing style props
 * for custom variants, with some defaults:
 *
 * @prop {ReactNode | ReactNodeArray} children - button content
 * @prop {string | object} border - to override border, default "none"
 * @prop {string | object} borderRadius - to override border-radius, default "27px"
 * @prop {string | object} bg - to override background color, default "white"
 * @prop {boolean} isLoading - form loading state to disable button
 */

export default function Button(props) {
  return (
    <Box
      as="button"
      type={props.type || 'button'}
      focusOutline="0"
      transition="all 0.2s"
      border={props.border || 'none'}
      borderRadius={props.borderRadius || '27px'}
      bg={props.bg || 'white'}
      cursor={!props.disabled && 'pointer'}
      disabled={props.disabled}
      {...props}
    >
      {props.children}
    </Box>
  );
}
